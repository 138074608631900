import { DTNote } from '@rabbit/data/types';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';
import { CreateNoteDto, DocIdParamDto, UpdateNoteDto } from '@rabbit/nest/api';

export const nestApiGetNote = FBF_CloudCallable<{ docid: string }, DTNote>(
  'api/note/:docid|GET'
);

export const nestApiCreateNote = FBF_CloudCallable<CreateNoteDto, any>(
  'api/note/create|POST'
);

export const nestApiUpdateNote = FBF_CloudCallable<DocIdParamDto & UpdateNoteDto, any>(
  'api/note/update/:docid|PUT'
);

export const nestApiDeleteNote = FBF_CloudCallable<DocIdParamDto, any>(
  'api/note/delete/:docid|PUT'
);

