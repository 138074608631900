import {
  Button,
  ButtonIcon,
  CardWrapperWithHeader,
  formatUnixTime,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { DocumentIcon, EyeIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
} from '@rabbit/data/types';
import {
  DTNote,
  NoteCategoryType,
  NoteStatus,
  NoteEntityType,
  Priority,
  CallOutcome,
  CommunicationDirection,
  InteractionChannel,
  NoteType,
  InteractionParty,
  InteractionMetadata
} from '@rabbit/data/types';
import { CreateTable } from '@rabbit/sage/utils/CreateTable.tsx';
import { useGetNotes } from '@rabbit/bizproc/react';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader.tsx';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';

import {
  nestApiGetNote,
  nestApiCreateNote,
  nestApiUpdateNote,
  nestApiDeleteNote
} from '@rabbit/bizproc/core';
import { toast } from 'react-toastify';
import { getPersonaData } from '@rabbit/sage/utils/helpers';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';

interface NotesDetailsInterface {
  entityId: string;
  entityType: NoteEntityType;
}

// const priorityOptions = Object.values(Priority).map(value => ({
//   label: value.charAt(0).toUpperCase() + value.slice(1),
//   value: value,
// }));

const categoryOptions = Object.values(NoteCategoryType).map(value => ({
  label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
  value: value,
}));

const noteTypeOptions = [
  { label: 'General Note', value: 'general' },
  { label: 'Call Log', value: 'call' },
  { label: 'Email Log', value: 'email' },
  { label: 'Customer Interaction', value: 'customer' },
];

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Please enter a title'),
  content: Yup.string().trim().required('Please enter a description'),
  category: Yup.string().required('Please select a category'),
});

export default function NotesDetailContentSection({
  entityType,
  entityId,
}: NotesDetailsInterface) {
  const [authorName, setAuthorName] = useState<string>('Unknown');
  const [viewNote, setViewNote] = useState<DTNote | null>(null);
  const [editNote, setEditNote] = useState<DTNote | null>(null);
  const [addNotes, setAddNotes] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { t } = useTranslation();
  const activePremiumTenant = t('tenantLink');

  const { notes, isLoading } = useGetNotes({
    tenantLink: activePremiumTenant,
    entityId,
    entityType,
  }) ?? {};

  const initialValues = {
    noteType: NoteType.GENERAL,
    title: '',
    content: '',
    date: new Date().getTime(),
    // priority: Priority.STANDARD,
    category: NoteCategoryType.GENERAL,
    status: NoteStatus.ACTIVE,
    entityType: entityType,
    entityId: entityId,
    metadata: undefined,
    attachments: [],
  };


  const onSubmit = async (values: typeof initialValues) => {
    try {
      setButtonLoading(true);

      const warrantor = getRootPersonaFromLexicon(
        t(PrincipalsFieldName),
        PersonaTypeSingleLetter.Warrantor
      );
      
      const noteData = {
        title: values.title,
        content: values.content,
        category: values.category,
        noteType: values.noteType,
        entityType: values.entityType,
        entityId: values.entityId,
        tenantLink: activePremiumTenant,
        metadata: values.metadata,
        attachments: values.attachments,
        warrantor: warrantor,
      }

      if (editNote) {
        const response = await nestApiUpdateNote({
          docid: editNote.docid,
          ...noteData
        });
        if (!response) throw new Error();
        setEditNote(null);
      } else {
        const response = await nestApiCreateNote(noteData);
        if (!response) throw new Error();
        setAddNotes(false);
      }

      setAddNotes(false);
    } catch (error) {
      console.error('NotesDetailContentSection: Error saving note:', error);
      toast.error(t('message.somethingWentWrongPleaseTryAgain'));
    } finally {
      setButtonLoading(false);
    }
  };

  const NotesTable = CreateTable<DTNote>(notes || [], [
    {
      header: t('general.date'),
      value: (data) => formatUnixTime(data.tcreate, 'dd/MM/yyyy'),
      accessorKey: 'tcreate', // Add this
    },
    {
      header: t('general.title'),
      value: (data) => data.title,
      accessorKey: 'title', // Add this
    },
    {
      header: t('general.category'),
      value: (data) => data.category,
      accessorKey: 'category', // Add this
    },
    {
      header: t('general.actions'),
      value: (data) => data.docid,
      Cell: ({ row }: any) => {
        // Find the original note using docid
        const originalNote = notes?.find(note => note.docid === row.original.actions);
        return (
          <div className="flex gap-2">
            <ButtonIcon
              kind="bgLightGreen"
              Icon={EyeIcon}
              label=""
              iconLeft={true}
              onClick={() => originalNote && setViewNote(originalNote)}
            />
            <ButtonIcon
              kind="bgLightGreen"
              Icon={PencilIcon}
              label=""
              iconLeft={true}
              onClick={() => originalNote && setEditNote(originalNote)}
            />
          </div>
        );
      },
    },
  ]);

  const renderMetadataFields = (values: typeof initialValues, setFieldValue: any) => {
    if (values.noteType === NoteType.INTERACTION) {
      return (
        <div className="space-y-4 bg-gray-50 rounded-md border border-gray-100 p-4">
          <h3 className="font-medium text-gray-700">{t('Interaction Details')}</h3>

          <Input
            type="select"
            name="metadata.channel"
            label={t('Channel')}
            settings={{
              options: Object.values(InteractionChannel).map(channel => ({
                label: channel.charAt(0).toUpperCase() + channel.slice(1).toLowerCase(),
                value: channel
              }))
            }}
          />

          <Input
            type="select"
            name="metadata.party"
            label={t('Interaction With')}
            settings={{
              options: Object.values(InteractionParty).map(party => ({
                label: party.charAt(0).toUpperCase() + party.slice(1).toLowerCase(),
                value: party
              }))
            }}
          />

          <Input
            type="select"
            name="metadata.direction"
            label={t('Direction')}
            settings={{
              options: Object.values(CommunicationDirection).map(dir => ({
                label: dir.charAt(0).toUpperCase() + dir.slice(1).toLowerCase(),
                value: dir
              }))
            }}
          />

          <Input
            type="text"
            name="metadata.contactInfo"
            label={t('Contact Information')}
            settings={{
              placeholder: 'Name, email, phone, etc.',
            }}
          />

          {/* Channel-specific fields */}
          {values.noteType === NoteType.INTERACTION && values.metadata && (
            <div className="grid grid-cols-2 gap-4">
              <Input
                type="time"
                name="metadata.duration"
                label={t('Duration')}
                settings={{ type: 'time', placeholder: 'hh:mm', }}
              />
              <Input
                type="phone"
                name="metadata.phoneNumber"
                label={t('Phone Number')}
                settings={{
                  placeholder: 'Phone number',
                }}
              />
            </div>
          )}

          {values.metadata && ((values.metadata as InteractionMetadata)?.channel === InteractionChannel.EMAIL) && (
            <>
              <Input
                type="email"
                name="metadata.subject"
                label={t('Subject Line')}
                settings={{
                  placeholder: 'Subject line',
                }}
              />
              <Input
                type="text"
                name="metadata.recipients"
                label={t('Recipients')}
                settings={{
                  placeholder: 'email@example.com, email2@example.com'
                }}
              />
            </>
          )}

          <Input
            type="checkbox"
            name="metadata.followUpRequired"
            label={t('Follow-up Required')}
            settings={{}}
          />

          {values.metadata && (values.metadata as InteractionMetadata)?.followUpRequired && (
            <Input
              type="datepicker"
              name="metadata.followUpDate"
              label={t('Follow-up Date')}
              settings={{
                placeholder: 'Follow-up Date',
              }}
            />
          )}
        </div>
      );
    }
    return null;
  };

  const NoteForm = ({ isEdit = false, onSubmit, initialData }: any) => (
    <Formik
      initialValues={initialData}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className="flex flex-col gap-6">
          <Input
            type="datepicker"
            name="date"
            label={t('Date')}
            settings={{
              placeholder: 'DD/MM/YYYY',
            }}
          />

          <Input
            type="text"
            name="title"
            label={t('Title')}
            settings={{
              placeholder: t('Enter note title'),
              hint: '*required',
            }}
          />

          <Input
            type="rich-text"
            name="content"
            label={t('Description')}
            settings={{
              placeholder: t('Enter note description'),
              hint: '*required',
            }}
          />

          <Input
            type="select"
            name="category"
            label={t('Category')}
            settings={{
              options: categoryOptions
            }}
          />

          <SageFileUploader
            label={t('Attachments')}
            identifiers={{
              category: UploadedFileCategories.NotesImages,
              docType: {
                docid: entityId,
                type: DocTypeShapeTypes.Note,
              },
              personaId: activePremiumTenant,
            }}
            onUploadCompleted={(files) => setFieldValue('attachments', files)}
            onDeleteFile={() => setFieldValue('attachments', [])}
            currentFiles={values.attachments}
            accepts={['image/*', '.pdf']}
            maxFiles={3}
          />

          <div className="flex gap-3 pt-2">
            <Button
              kind="primary"
              type="submit"
              disabled={isSubmitting}
              loading={buttonLoading}
            >
              {t('Save')}
            </Button>
            <Button
              kind="outline_red"
              onClick={() => {
                if (isEdit) {
                  setEditNote(null);
                } else {
                  setAddNotes(false);
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );

  // Add this effect to fetch author data
  useEffect(() => {
    if (viewNote?.updatedBy) {  // Change createdBy to updatedBy if that's the field name in DTNote
      getPersonaData(viewNote.updatedBy)
        .then((authorData) => {
          console.log('Author data received:', authorData);
          if (authorData) {
            // TODO: get the name from the author data
            setAuthorName( 'Unknown');
          }
        })
        .catch((error) => {
          console.error('Error fetching author data:', error);
          setAuthorName('Unknown');
        });
    }
  }, [viewNote]);

  return (
    <>
      <CardWrapperWithHeader
        title={t('Notes')}
        headerRight={
          <Button
            icon={<PlusIcon />}
            kind="primary"
            size={'sm'}
            onClick={() => setAddNotes(true)}
          >
            {t('Add note')}
          </Button>
        }
      >
        <div className="max-h-[300px] overflow-y-auto">
          <NotesTable
            initialState={{
              showGlobalFilter: true,
              sorting: [{ id: 'date', desc: true }],
            }}
            muiSearchTextFieldProps={{
              placeholder: t('general.searchByIDOrProductName'),
            }}
            state={{
              isLoading: false,
            }}
            enablePagination={false}
          />
        </div>
      </CardWrapperWithHeader>

      {/* View Note Modal */}
      {viewNote && (
        <Modal
          kind="generic"
          settings={{
            title: t('View Note'),
            handleClose: () => setViewNote(null),
          }}
          className="w-[600px] rounded-md bg-white"
        >
          <div className="flex flex-col gap-4 p-6">
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-500">{t('Date')}</p>
                <p className="text-base mt-1">{formatUnixTime(viewNote.tcreate, 'dd/MM/yyyy')}</p>
              </div>

              <div>
                <p className="text-sm text-gray-500">{t('Title')}</p>
                <p className="text-lg font-medium mt-1">{viewNote.title}</p>
              </div>

              <div>
                <p className="text-sm text-gray-500">{t('Category')}</p>
                <div className="mt-1">
                  <span className={`inline-flex rounded-md px-3 py-1 text-sm font-medium
              ${viewNote.category === 'complaint' ? 'bg-red-100 text-red-800' :
                      viewNote.category === 'support' ? 'bg-blue-100 text-blue-800' :
                        viewNote.category === 'feedback' ? 'bg-green-100 text-green-800' :
                          viewNote.category === 'sales' ? 'bg-purple-100 text-purple-800' :
                            viewNote.category === 'technical' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {viewNote.category.charAt(0).toUpperCase() + viewNote.category.slice(1)}
                  </span>
                </div>
              </div>

              <div>
                <p className="text-sm text-gray-500">{t('Description')}</p>
                <div className="mt-1 p-4 bg-gray-50 rounded-md">
                  <div dangerouslySetInnerHTML={{ __html: viewNote.content }} />
                </div>
              </div>

              {viewNote.attachments && viewNote.attachments.length > 0 && (
                <div>
                  <p className="text-sm text-gray-500">{t('Attachments')}</p>
                  <div className="mt-1 space-y-2">
                    {viewNote.attachments.map((file) => (
                      <a
                        key={file.ogFilename}
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-blue-600 hover:underline"
                      >
                        <DocumentIcon className="h-4 w-4" />
                        {file.metadata?.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="text-sm text-gray-500 border-t pt-4">
              {`${t('Created by')}: ${authorName} | ${t('Last modified')}: ${formatUnixTime(viewNote.tupdate || viewNote.tcreate, 'dd/MM/yyyy HH:mm a')}`}
            </div>

            <div className="flex justify-end gap-3 pt-4 mt-4 border-t">
              <Button
                kind="outline"
                onClick={() => {
                  setViewNote(null);
                  setEditNote(viewNote);
                }}
              >
                {t('Edit')}
              </Button>
              <Button kind="outline_red" onClick={() => setViewNote(null)}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {/* Add Note Modal */}
      {(addNotes || editNote) && (
        <Modal
          kind="generic"
          settings={{
            title: editNote ? t('Edit Note') : t('Add Note'),
            handleClose: () => setAddNotes(false),
          }}
          className="w-[600px] rounded-md bg-white p-6"
        >
          <NoteForm
            isEdit={!!editNote}
            onSubmit={onSubmit}
            initialData={editNote || initialValues}
          />
        </Modal>
      )}
    </>
  );
}