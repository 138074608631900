import {
  ButtonIcon,
  LoadingSpinner,
  MenuListSelect,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CreateNewConsumerForm_FormValues } from '@rabbit/sage/components/organisms/ModalNewRegistration/tenant-config/types.ts';
import {
  FileStorageContext,
  getCompletedUploadsOfCategory,
} from '@rabbit/bizproc/react';
import {
  DTVendable,
  FBD_Consumer_Private,
  FBD_Holding_Private,
} from '@rabbit/data/types';
import {
  CompletedUploadShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { SelectChangeEvent } from '@mui/material';
import { Error, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useQueryParams } from 'raviger';
import { SearchVendablesBySkuCode } from '@rabbit/search/cherchons';
import { AppContext } from '@rabbit/app-context';
import PartnerDetailsView from '@rabbit/sage/views/PartnerDetailsView.tsx';
import CustomerDetailsView from '@rabbit/sage/views/CustomerDetailsView.tsx';
import ProductDetailsView from '@rabbit/sage/views/ProductDetailsView.tsx';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';
import { nestApiRegisterInstallerHoldings } from '@rabbit/bizproc/core';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function RegisterProductInstaller() {
  const { t, i18n } = useTranslation();
  const params = useQueryParams();
  const steps = ['installer-create', 'customer-create', 'register-product'];
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [customerData, setCustomerData] =
    useState<CreateNewConsumerForm_FormValues>();
  const [productData, setProductData] = useState<any>();
  const [installerData, setInstallerData] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [defaultSelected, setDefaultSelected] = useState<any>(undefined);
  const [productLoaded, setProductLoaded] = useState<boolean>(false);
  const [vendable, setVendable] = useState<DTVendable | null>(null);
  const [errorSubmit, setErrorSubmit] = useState<boolean>(false);

  const { config } = useContext(AppContext);

  const { uploadQueueState } = useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};

  const installerAttachmentUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.installation_attachment
    );

  const proofAttachmentUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.ConsumerProofPurchase
    );

  const installationAttachmentFiles = installerAttachmentUploads?.flatMap(
    (file) =>
      file.category === UploadedFileCategories.installation_attachment &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
  );
  const proofAttachmentFiles = proofAttachmentUploads?.flatMap((file) =>
    file.category === UploadedFileCategories.ConsumerProofPurchase &&
    file?.uploadedFile
      ? [file.uploadedFile]
      : []
  );

  const onSubmitProductData = async (values: any) => {
    setProductData(values);
    setIsSubmitting(true);
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';
    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;
    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.purchase_country
        : values.purchase_location?.country ?? '';
    const finalData = {
      ...installerData,
      ...customerData,
      ...values,
      consumer_holding_purchase_location: newPurchaseLocation,
      consumer_holding_purchase_location_other: newPurchaseLocationOther,
      consumer_holding_purchase_country: newPurchaseCountry,
    };
    finalData.installation_attachment = installationAttachmentFiles;
    finalData.consumer_proof_of_purchase = proofAttachmentFiles;
    try {
      const response = await nestApiRegisterInstallerHoldings({
        formData: finalData,
        tenantLink: t('tenantLink'),
        newHoldingLink: holding_docid,
        newConsumerLink: newConsumerLink,
      });
      if (response) {
        setIsSubmitting(false);
        setRegistered(true);
      } else {
        setIsSubmitting(false);
        setErrorSubmit(true);
      }
    } catch (error) {
      setErrorSubmit(true);
    }
  };
  useEffect(() => {
    (async () => {
      const sku = params[0] && params[0].sku ? params[0].sku : '';
      if (sku && !productLoaded) {
        const vendableData = await SearchVendablesBySkuCode(sku);
        if (vendableData && vendableData.items[0]) {
          setVendable(vendableData.items[0]);
          setDefaultSelected({
            id: vendableData.items[0].docid,
            label: vendableData.items[0].title,
            value: vendableData.items[0].docid,
          });
          setProductLoaded(true);
        } else {
        }
      } else {
        setProductLoaded(true);
      }
    })();
  }, [setProductLoaded]);

  const holding_private = FBD_Holding_Private.empty();
  const holding_docid = FBD_Holding_Private.keygen(
    holding_private,
    FBD_Holding_Private
  );

  const consumer_private = FBD_Consumer_Private.empty();
  const newConsumerLink = FBD_Consumer_Private.keygen(
    consumer_private,
    FBD_Consumer_Private
  );

  const [lang, setLang] = useState(window.localStorage.getItem('lang') ?? 'en');

  const handleChange = async (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
    window.localStorage.setItem('lang', event.target.value as string);
    await i18n.changeLanguage(event.target.value as string);
  };
  const availableLanguages = AVAILABLE_LANGUAGE_CODES.map((data) => ({
    value: data.code,
    label: config.ACCOUNT.LANGUAGE_LABEL ? data.language : data.code,
  }));
  const cobrand = t('CFG_COBRAND_LOGO');
  const productPlaceHolder = t(
    'CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER',
    { defaultValue: '' }
  );
  const defaultImage =
    t('CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER') || productPlaceHolder;

  return (
    <>
      {(registered || errorSubmit) && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[480px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          <div className="pl-6 pt-10 pb-4">
            {errorSubmit && (
              <>
                <Error height={30} style={{ position: 'absolute' }} />{' '}
                <span className="font-nunito ml-10">
                  {t('message.productRegisterFailed')}
                </span>
              </>
            )}
            {registered && (
              <>
                <div className="flex flex-col">
                  {/*<div style={{ position: 'absolute', right: 10, top: 10 }}>
                    <div style={{ position: 'absolute', right: 10, top: 10 }}>
                      <MenuListSelect
                        label="lang"
                        value={lang}
                        handleChange={handleChange}
                        menuItem={availableLanguages}
                      />
                    </div>
                  </div>*/}
                  <img
                    alt=""
                    src={cobrand}
                    className="mb-2.5 max-h-[72px] w-full object-contain"
                  />
                  <div className={`flex flex-col ${vendable ? 'gap-5' : ''}`}>
                    <div>
                      {vendable && (
                        <div
                          className={`flex items-center ${
                            vendable ? 'h-[360px]' : 'h-[400px]'
                          } w-full`}
                        >
                          <img
                            alt=""
                            src={
                              vendable
                                ? vendable.img && vendable.img.length > 0
                                  ? vendable.img[0]
                                  : defaultImage
                                : defaultImage
                            }
                            className="h-full w-full object-contain"
                          />
                        </div>
                      )}

                      {vendable && (
                        <>
                          <div className="font-nunito text-center text-xl font-medium">
                            {vendable.title}
                          </div>
                          <div className="mt-10 flex grow items-center justify-center gap-3 pb-10 text-xl">
                            <div className="flex h-[30px] w-[30px] shrink-0 items-center justify-center border-gray-300 bg-white">
                              <ShieldCheckIcon className="text-primary-600 h-8 w-8" />
                            </div>
                            {t('general.productRegisteredSuccess')}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {isSubmitting && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[720px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          <div className="pl-6 pt-4 pb-4">
            <LoadingSpinner size={'lg'} />
          </div>
        </div>
      )}
      {!isSubmitting && !registered && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[720px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          {/*<div style={{ position: 'absolute', right: 10, top: 10 }}>
            <MenuListSelect
              label="lang"
              value={lang}
              handleChange={handleChange}
              menuItem={availableLanguages}
            />
          </div>*/}
          <div className="p-2">
            <div className="flex max-h-[100px] w-full">
              <img
                src={vendable && vendable.img ? vendable?.img[0] : defaultImage}
                className="w-[100px] object-contain"
                alt=""
              />
              <div className="ml-5 flex-1 pt-1">
                <div className="font-nunito mt-0 pt-0 text-xl font-semibold">
                  {t('general.productRegistration')}
                </div>
                <div className="font-nunito text-sm text-gray-600">
                  {vendable && vendable.title ? vendable.title : ''}
                </div>
              </div>
            </div>
          </div>
          <Accordion
            hidden={currentStep !== steps[0]}
            expanded={currentStep === steps[0]}
          >
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <div className="font-nunito text-lg">
                <span className="font-nunito">
                  {t('general.installerDetails')}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <PartnerDetailsView
                steps={steps}
                setInstallerData={setInstallerData}
                setCurrentStep={setCurrentStep}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            hidden={currentStep !== steps[1]}
            expanded={currentStep === steps[1]}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              onClick={() => setCurrentStep(steps[0])}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="font-nunito text-lg">
                <span className="font-nunito">
                  {t('general.customerDetails')}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerDetailsView
                steps={steps}
                setCustomerData={setCustomerData}
                setCurrentStep={setCurrentStep}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            hidden={currentStep !== steps[2]}
            expanded={currentStep === steps[2]}
          >
            <AccordionSummary
              onClick={() => setCurrentStep(steps[1])}
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="font-nunito text-lg">
                <span className="font-nunito">
                  {t('general.productDetails')}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ProductDetailsView
                steps={steps}
                setCurrentStep={setCurrentStep}
                defaultSelected={defaultSelected}
                onSubmitProductData={onSubmitProductData}
                holding_docid={holding_docid}
                newConsumerLink={newConsumerLink}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
}
