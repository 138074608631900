import { query, orderBy, where } from 'firebase/firestore';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { DR_Note } from '@rabbit/data/portal';
import { useEffect, useState } from 'react';
import { DTNote, NoteEntityType } from '@rabbit/data/types';

interface UseGetNotesProps {
  tenantLink: string;
  entityId: string;
  entityType: NoteEntityType;
}

export function useGetNotes({ tenantLink, entityId, entityType }: UseGetNotesProps) {
  const [queryEnabled, setQueryEnabled] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                              Query Constraints                               */
  /* -------------------------------------------------------------------------- */

  const queryConstraints = [
    where('tenantLink', '==', tenantLink),
    where('entityId', '==', entityId),
    where('entityType', '==', entityType),
    orderBy('tcreate', 'desc'), // Most recent notes first
  ];

  /* ------------------------------ Create query ------------------------------ */
  const noteQuery = query(
    DR_Note.collection(),
    ...queryConstraints
  );

  const noteList = useFirestoreQueryData(
    ['notes', tenantLink, entityId, entityType],
    noteQuery,
    { subscribe: true },
    {
      enabled: queryEnabled,
      refetchOnWindowFocus: true,
      retry: true,
      onError: (err) => {
        console.error('Error fetching notes:', err);
      },
    }
  );

  useEffect(() => {
    const canRunQuery = () => {
      if (!tenantLink || !entityId || !entityType) return false;
      return true;
    };
    setQueryEnabled(canRunQuery());
  }, [tenantLink, entityId, entityType]);

  return {
    notes: noteList.data ?? [],
    isLoading: noteList.isLoading || queryEnabled === false,
  };
}