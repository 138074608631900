import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  ButtonLink,
  Input,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import {
  LIST_CURRENCIES,
  useSendEmail,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  getDefaultAddress,
  getPartnerLabelByValue,
  getPartnerOptions,
} from '@rabbit/sage/utils/helpers';
import {
  AddressModalKind,
  EditAddAddressModal,
} from '../../molecules/account-management/EditAddAddressModal/EditAddAddressModal';
import {
  Address,
  BusinessUserInviteType,
  DTTenant_Public,
  PersonaTypeFullKeyValues,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { AppContext } from '@rabbit/app-context';
import { nestApiGenerateBusinessUserInvite } from '@rabbit/bizproc/core';

interface FormValuesShape {
  partner_name: string;
  partner_email: string;
  partner_type: PersonaTypeSingleLetter[];
  partner_phone_number: string;
  partner_phone_number_e164?: string;
  partner_addresses?: Address[];
  increased_labour_rate: boolean;
  labour_rate?: { amount: string; currency: string | undefined } | undefined;
  labour_rate_tier_1?: { amount: string; currency: string };
  labour_rate_tier_2?: { amount: string; currency: string };
  labour_rate_tier_3?: { amount: string; currency: string };
  termsAndConditions: boolean;
}

export interface ModalNewEditPartnerProps {
  handleClose: () => void;
  handleDelete: () => void;
  onSuccess?: () => void;
  partner?: DTTenant_Public & {
    personas?: any[];
    firstName?: string;
    lastName?: string;
  }; // TODO: Change to official
  modalSettings: ModalSettingsShape;
  installerSettings?: any;
}

const inputTypeCurrencyOverrideOptions: Array<SelectOptionShape> =
  LIST_CURRENCIES.map((currency, index) => ({
    id: `${index}`,
    label: currency.code + '/h',
    value: currency.code,
  }));

export function ModalNewEditPartner({
  handleClose,
  handleDelete,
  onSuccess,
  partner,
  modalSettings,
  installerSettings = {},
}: ModalNewEditPartnerProps) {
  const {
    generateBusinessUserInvite,
    getPartnerTenantData,
    updatePartnerTenantData,
    isReady,
  } = useSageAPI();
  const navigate = useNavigate();
  const formRef = useRef(null) as any;
  // const { createConsumerProfile, editConsumerProfile } = useConsumerProfile(
  //   partner?.docid ?? null
  // );

  const { config, tenantInfo } = useContext(AppContext);
  const isTcRequired = useMemo(
    () => config.PARTNERS.REGISTRATION.TC_REQUIRED,
    [config]
  );
  const readPartner: any | undefined = partner;

  const isNewPartner = partner?.docid ? false : true;
  const isInstaller = installerSettings.installer;
  const [isLoading, setIsLoading] = useState(!isNewPartner);
  //const [manageAddressesModal, setManageAddressesModal] = useState(false);
  const [addressModal, setAddressModal] = useState<
    'edit' | 'new' | undefined
  >();

  const { t } = useTranslation();
  const tenantLink = t('tenantLink');

  const [currentAddresses, setCurrentAddresses] = useState<any>(
    partner?.info?.address || readPartner?.addresses || []
  );
  const [defaultAddress, setDefaultAddress] = useState<Address | undefined>(
    partner?.info?.address
      ? getDefaultAddress(partner?.info?.address)
      : undefined
  );

  const updateAddresses = (addresses: Address[]) => {
    setCurrentAddresses(addresses);
    formRef.current.setFieldValue('partner_addresses', addresses);
  };

  const onAddressAdded = async (
    address: Address,
    mode: 'edit' | 'new',
    kind: AddressModalKind
  ) => {
    if (kind === 'partner') {
      if (mode === 'new') {
        const newAddresses = [...currentAddresses, address];
        updateAddresses(newAddresses);
      }
      if (mode === 'edit') {
        const newAddresses = currentAddresses.filter((item: any) => {
          return item.id !== address.id;
        });
        newAddresses.push(address);

        // Set isDefault to false for remaining items if address.isDefault is true
        if (address.isDefault) {
          newAddresses.forEach((item: any) => {
            if (item.id !== address.id) {
              item.isDefault = false;
            }
          });
        }
        updateAddresses(newAddresses);
      }
    }
  };

  const PinnacleLabourRatesSchema = {
    labour_rate: Yup.object({
      amount: Yup.number()
        .required(t('message.pleaseEnterLabourRate'))
        .min(1, t('message.amountMustBeBiggerThanZero')),
      currency: Yup.string().required(t('message.pleaseSelectCurrency')),
    }),
    labour_rate_tier_1: Yup.object({
      amount: Yup.number().min(1, t('message.amountMustBeBiggerThanZero')),
      currency: Yup.string(),
    }),
    labour_rate_tier_2: Yup.object({
      amount: Yup.number().min(1, t('message.amountMustBeBiggerThanZero')),
      currency: Yup.string(),
    }),
    labour_rate_tier_3: Yup.object({
      amount: Yup.number().min(1, t('message.amountMustBeBiggerThanZero')),
      currency: Yup.string(),
    }),
  };

  const addressSchema = Yup.object().shape({
    line1: Yup.string().trim().required('Please enter an address.'),
    line2: Yup.string().trim(),
    label: Yup.string().trim().required('Please enter a label'),
    town: Yup.string().trim().required('Please enter a city / suburb.'),
    state: Yup.string().trim().required('Please enter a state / territory.'),
    postcode: Yup.string().trim().required('Please enter a post code.'),
    country: Yup.string().trim().required('Please enter a country.'),
    isDefault: Yup.bool().default(false),
  });

  const schema = Yup.object({
    partner_name: Yup.string().required(t('message.pleaseEnterAName')),
    partner_email: Yup.string()
      .trim()
      .required('Required')
      .email(t('message.pleaseEnterAValidEmailAddress')),
    partner_phone_number: Yup.string()
      .trim()
      .required(t('message.pleaseEnterAPhoneNumber')),
    partner_type:
      isNewPartner || !isInstaller
        ? Yup.array().required(t('message.pleaseSelectAType'))
        : Yup.array(),
    increased_labour_rate: Yup.boolean(),
    termsAndConditions: isTcRequired
      ? Yup.bool()
          .oneOf([true], 'You must accept the Terms & Conditions')
          .required('You must accept the Terms & Conditions')
      : Yup.bool(),
    ...(tenantLink === 'PINNACLEWARRANTIES' && PinnacleLabourRatesSchema),
    partner_addresses: config.PARTNERS.REGISTRATION.ADDRESS_REQUIRED
      ? Yup.array(addressSchema)
          .min(1)
          .required('Please enter at least one address.')
      : Yup.array(addressSchema),
  });

  const PinnacleLabourRatesInitValues = {
    labour_rate: { amount: '', currency: tenantInfo?.currency },
    labour_rate_tier_1: { amount: '', currency: tenantInfo?.currency },
    labour_rate_tier_2: { amount: '', currency: tenantInfo?.currency },
    labour_rate_tier_3: { amount: '', currency: tenantInfo?.currency },
  };

  const [initialValues, setInitialValues] = useState<any>({
    partner_name: partner?.orgName ?? '',
    partner_email: partner?.info?.email || readPartner?.email || '',
    partner_phone_number: partner?.info?.phone || readPartner?.phone || '',
    partner_phone_number_e164: partner?.info?.phone || readPartner?.phone || '',
    partner_type: (partner?.personas && partner?.personas[0]) || '',
    labour_rate: {
      amount: '',
      currency: tenantInfo?.currency,
    },
    increased_labour_rate: false,
    termsAndConditions: false,
    ...(tenantLink === 'PINNACLEWARRANTIES' && PinnacleLabourRatesInitValues),
    partner_addresses: currentAddresses || [],
  });

  useEffect(() => {
    if (!currentAddresses) return;
    const defaultAddress = getDefaultAddress(currentAddresses);
    setDefaultAddress(defaultAddress);
  }, [currentAddresses]);

  useEffect(() => {
    if (!partner?.info?.address) return;
    const currentAddresses = partner?.info?.address;
    setCurrentAddresses(currentAddresses);
  }, [partner]);

  useEffect(() => {
    if (isReady) {
      if (partner?.docid) {
        setIsLoading(true);
        (async () => {
          const res = await getPartnerTenantData({
            partnerTenant: partner?.docid,
            userTenant: t('tenantLink'),
          });
          if (!res.private && !res.public) {
            toast.error(
              t(
                'message.failedToGetTheDataForThisPartnerPleaseContactIWarranty'
              )
            );
            handleClose();
          } else {
            const partnerSettings =
              res.private?.partnerSettings?.filter(
                (i) => i.partner === t('tenantLink')
              ) ?? [];

            // TODO: this might be slightly more complicated in the future. It's not even currently needed, but ehh. Hopefully it'll be useful in the future
            const partnerType = res.private.rootPersonas.warrantyDealer
              ? [
                  PersonaTypeSingleLetter.WarrantyDealer,
                  PersonaTypeSingleLetter.Retailer,
                ]
              : [PersonaTypeSingleLetter.Repairer];

            if (formRef.current) {
              formRef.current.setFieldValue('partner_type', partnerType);
              formRef.current.setFieldValue(
                'increased_labour_rate',
                partnerSettings.length >= 1 &&
                  partnerSettings[0]?.labourRates &&
                  (partnerSettings[0]?.labourRates?.tier1 ||
                    partnerSettings[0]?.labourRates?.tier2 ||
                    partnerSettings[0]?.labourRates?.tier3)
                  ? true
                  : false
              );
              formRef.current.setFieldValue('labour_rate', null);
              if (tenantLink === 'PINNACLEWARRANTIES') {
                formRef.current.setFieldValue('labour_rate', {
                  amount:
                    partnerSettings[0]?.labourRates?.default?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    tenantInfo?.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_1', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier1?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    tenantInfo?.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_2', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier2?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    tenantInfo?.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_3', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier3?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    tenantInfo?.currency,
                });
              }
            }
            setIsLoading(false);
          }
        })().catch((e) => {
          console.error(e);
          toast.error(t('message.somethingWentWrongPleaseContactIWarranty'));
          handleClose();
        });
      }
    }
    if (
      partner?.personas &&
      partner?.personas[0] === PersonaTypeSingleLetter.Installer
    ) {
      formRef.current.setFieldValue('partner_type', [
        PersonaTypeFullKeyValues.installer,
      ]);
    }
  }, [partner?.docid, isReady]);

  const handleSubmit = async (values: FormValuesShape) => {
    setIsLoading(true);
    const partnerData = {
      email: values.partner_email,
      orgName: values.partner_name,
      phone: values.partner_phone_number_e164 ?? values.partner_phone_number,
      type: BusinessUserInviteType.Partner,
      firstName: partner?.firstName ?? '',
      lastName: partner?.lastName ?? '',
      personas: Array.isArray(values.partner_type)
        ? values.partner_type
        : [values.partner_type],
      addresses: currentAddresses,
      tenantLink: t('tenantLink'),
      settings: [
        {
          partner: t('tenantLink'),
          labourRates: {
            default: values?.labour_rate?.amount
              ? Number(values.labour_rate.amount)
              : null,
            tier1: values.labour_rate_tier_1?.amount
              ? Number(values.labour_rate_tier_1?.amount)
              : null,
            tier2: values.labour_rate_tier_2?.amount
              ? Number(values.labour_rate_tier_2?.amount)
              : null,
            tier3: values.labour_rate_tier_3?.amount
              ? Number(values.labour_rate_tier_3?.amount)
              : null,
            currency: tenantInfo?.currency ?? 'GBP',
          },
        },
      ],
    };

    if (isNewPartner) {
      try {
        const res = (await nestApiGenerateBusinessUserInvite({
          inviteData: partnerData,
          baseUrl: window.location.origin,
          emailParams: {
            from: tenantInfo?.emailInfo?.emailSender ?? '',
            business_name: tenantInfo?.name ?? '',
            business_logo: tenantInfo?.logo ?? '',
            main_template: tenantInfo?.emailInfo?.emailMainTemplate ?? '',
            partner_type:
              getPartnerLabelByValue(partnerData.personas[0], config) ?? '',
            partner_name: partnerData.orgName ?? '',
          },
          tenantLink: tenantLink,
        })) as any;

        if (!res) {
          toast.error(t('message.somethingWentWrongPleaseTryAgain'));
          setIsLoading(false);
          return console.log('error', res);
        }

        if (res?.doc && res?.doc?.docid) {
          toast.success(t('message.partnerInvitationSentSuccessfully'));
          if (onSuccess) {
            onSuccess();
          }
          navigate(SAGE_ROUTE_NAME.MANAGE + '/partner/' + res.doc.docid);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data.message;
        console.error(error);
        setIsLoading(false);
        toast.error(t(errMsg) || t('message.somethingWentWrongPleaseTryAgain'));
        return;
      }
    } else {
      // TODO: Calling function directly here for now until useSageApi hook is fully implemented - then it should be moved there

      if (partner?.docid) {
        const res = await updatePartnerTenantData({
          userTenant: t('tenantLink'),
          partnerTenant: partner?.docid,
          formData: {
            orgName: values.partner_name,
            phone: values.partner_phone_number,
            addresses: currentAddresses,
            settings: [
              {
                partner: t('tenantLink'),
                labourRates: {
                  default: values?.labour_rate?.amount
                    ? Number(values.labour_rate.amount)
                    : null,
                  tier1: values.labour_rate_tier_1?.amount
                    ? Number(values.labour_rate_tier_1?.amount)
                    : null,
                  tier2: values.labour_rate_tier_2?.amount
                    ? Number(values.labour_rate_tier_2?.amount)
                    : null,
                  tier3: values.labour_rate_tier_3?.amount
                    ? Number(values.labour_rate_tier_3?.amount)
                    : null,
                  currency: tenantInfo?.currency ?? 'GBP',
                },
              },
            ],
          },
        });

        if (!res) {
          toast.error(t('message.somethingWentWrongPleaseTryAgain'));
          setIsLoading(false);
          return console.log('error', res);
        }
        if (onSuccess) {
          onSuccess();
        }
        toast.success(t('message.partnerUpdatedSuccessfully'));
      }
    }

    setIsLoading(false);
    handleClose();
  };

  const openTc = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    window.open(`${SAGE_ROUTE_NAME.TERMS_AND_CONDITIONS}`, '_blank');
  };

  const addressRequired = config.PARTNERS.REGISTRATION.ADDRESS_REQUIRED;

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-[724px] rounded-md border bg-white"
      isLoading={isLoading}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formRef}
      >
        {(props) => (
          <Form className="mt-2 w-full px-5">
            <div className="w-full py-2">
              <div className="flex w-full gap-4">
                <Input
                  type="text"
                  label={`${t('general.companyName')}*`}
                  name="partner_name"
                  settings={{
                    id: 'name',
                    hint: '*required',
                  }}
                />
                <Input
                  type="email"
                  label={`${t('general.email')}*`}
                  name="partner_email"
                  settings={{
                    id: 'email',
                    disabled: !isNewPartner || isInstaller,
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="flex w-full gap-4 py-2">
              <Input
                type="phone"
                name="partner_phone_number"
                label={`${t('general.phoneNumber')}*`}
                settings={{
                  placeholder: t('general.phoneNumber'),
                  hint: '*required',
                  disabled: isInstaller,
                }}
              />
              {isNewPartner && (
                <Input
                  type="select"
                  name="partner_type"
                  label={`${t('general.type')}*`}
                  settings={{
                    id: 'partner_type',
                    placeholder: t('message.pleaseSelectAnOption'),
                    hint: '*required',
                    options: getPartnerOptions(config),
                    disabled: !isNewPartner,
                    isMulti: false,
                  }}
                />
              )}
            </div>
            <div>
              <ButtonLink
                label={
                  t('general.partnerAddress') + (addressRequired ? '*' : '')
                }
                className={
                  props.errors.partner_addresses
                    ? 'border-red-500 bg-red-100'
                    : ''
                }
                onClick={() =>
                  currentAddresses.length > 0
                    ? setAddressModal('edit')
                    : setAddressModal('new')
                }
              >
                {defaultAddress ? (
                  <div>
                    {defaultAddress?.postcode} {defaultAddress?.line1},{' '}
                    {defaultAddress?.line2} {defaultAddress?.town}{' '}
                    {defaultAddress?.state} {defaultAddress?.country}
                  </div>
                ) : (
                  <div>{t('general.addAddress')}</div>
                )}
              </ButtonLink>
            </div>
            {tenantLink === 'PINNACLEWARRANTIES' && (
              <div className="mt-4 flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="currency"
                    name="labour_rate"
                    label={`${t('general.defaultLabourRate')}*`}
                    settings={{
                      id: 'labour_rate',
                      placeholder: t('general.amount'),
                      hint: '*required',
                      currency: tenantInfo?.currency,
                      inputTypeCurrencyOverrideOptions,
                    }}
                  />
                </div>
                <div>
                  <Input
                    type="checkbox"
                    name="increased_labour_rate"
                    settings={{
                      checkboxLabel: t('message.increaseLabourRate'),
                    }}
                  />
                </div>
                {props.values.increased_labour_rate && (
                  <div className="grid grid-cols-3 gap-4">
                    <Input
                      type="currency"
                      name="labour_rate_tier_1"
                      label="Tier 1"
                      settings={{
                        id: 'labour_rate_tier_1',
                        placeholder: t('general.amount'),
                        currency: tenantInfo?.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                    <Input
                      type="currency"
                      name="labour_rate_tier_2"
                      label="Tier 2"
                      settings={{
                        id: 'labour_rate_tier_2',
                        placeholder: t('general.amount'),
                        currency: tenantInfo?.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                    <Input
                      type="currency"
                      name="labour_rate_tier_3"
                      label="Tier 3"
                      settings={{
                        id: 'labour_rate_tier_3',
                        placeholder: t('general.amount'),
                        currency: tenantInfo?.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {/* {
              !isNewPartner && manageAddressesModal && (
                <ModalManageAddresses
                  data={partner}
                  handleClose={() => setManageAddressesModal(false)}
                />
              )
            } */}
            {/* Disabling deactivate toggle for now
            {partner?.docid && (
              <div className="font-nunito mt-4 flex justify-between gap-4 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-base text-gray-500 opacity-50">
                Deactivate
                <Toggle checked={false} onChange={() => 0} disabled={true} />
              </div>
            )} */}

            {isTcRequired && (
              <div className="relative mt-4 flex items-center">
                <Input
                  type="checkbox"
                  settings={{ id: 'termsAndConditions' }}
                  name="termsAndConditions"
                  className="text-primary-600 focus:ring-primary-500 h-4 w-full rounded border-gray-300"
                />
                <label
                  htmlFor="termsAndConditions"
                  className="absolute left-5 top-0 ml-2 block text-sm text-gray-700"
                >
                  I have read{' '}
                  <a
                    href="#"
                    onClick={openTc}
                    className="text-blue-600 underline"
                  >
                    Terms & Conditions
                  </a>{' '}
                  and agree
                </label>
              </div>
            )}

            <div className="mt-8 flex gap-5 py-2">
              <Button
                loading={isLoading}
                kind="primary"
                size="md"
                className="shrink-0 grow"
                type="submit"
                disabled={props.isSubmitting || !props.isValid}
                children={t('general.save')}
              />
            </div>
          </Form>
        )}
      </Formik>
      {addressModal && (
        <EditAddAddressModal
          handleClose={() => setAddressModal(undefined)}
          mode={addressModal}
          kind={'partner'}
          address={
            currentAddresses.length > 0
              ? currentAddresses[0]
              : {
                  line1: '',
                  line2: '',
                  town: '',
                  state: '',
                  postcode: '',
                  country: '',
                  isDefault: true,
                }
          }
          handleSubmit={onAddressAdded}
          handleDelete={async (address) =>
            updateAddresses(
              currentAddresses.filter((i: Address) => i.line1 !== address.line1)
            )
          }
        />
      )}
    </Modal>
  );
}
